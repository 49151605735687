import Vue from 'vue';
import router from '@/app/app-routes';
import store from '@/app/app-state';
import { COURIER_STORE_MODULE_NAME } from '@/app/couriers/courierStoreModule';
import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';
import { Notification } from 'element-ui';

const routeData = Vue.observable({ params: {} });

router.afterEach((route) => {
  routeData.params = route.params;
});

export const useCourierView = () => {
  const today = moment().format('YYYY-MM-DD');
  const date = ref(today);
  const dateCourierList = ref(today);
  const editableTabs = ref(JSON.parse(localStorage.getItem('courierTabs')));
  const editableTabsValue = ref('today');
  const isVisibleAddTab = ref(false);
  const documentsPreviewRef = ref(null);
  const createCourierOrderRef = ref(null);
  const previewFileTable = (url) => {
    documentsPreviewRef.value.open(url);
  };
  const couriers = computed(
    () => store.getters[`${COURIER_STORE_MODULE_NAME}/autocompleteCouriers`]
  );

  const fetchCouriers = () => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/fetchCouriers`);
  };

  fetchCouriers();

  const formatAddress = (district, address) => {
    const addr = address.find((a) => a.isMain);
    if (addr) {
      if (addr.districtName) {
        return `р-н ${addr.districtName}, ул. ${addr.street} д. ${addr.house}`;
      }
      return `ул. ${addr.street}`;
    }
    return '-';
  };

  const formatComment = (address) => {
    const addr = address.find((a) => a.isMain);
    if (addr) {
      return addr.comment;
    }
    return '-';
  };

  const fetchCourier = (date) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/fetchCourier`, { id: routeData.params.id, date });
  };

  const unbindCourierToOrder = (orderId, courierId) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/unbindCourierToOrder`, { courierId, orderId });
  };

  const bindCourierToOrder = (bindingsArray) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/bindCourierToOrder`, { bindingsArray });
  };

  const updateOrdCouriers = (ords, name) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/updateCourier`, {
      id: routeData.params.id,
      payload: { ords, name },
    });
  };

  const courier = computed(() => store.state[COURIER_STORE_MODULE_NAME].courier);

  const courierOrders = computed(() => store.state[COURIER_STORE_MODULE_NAME].courier.orders);

  watch(courierOrders, (orders) => {
    updateOrdCouriers(
      orders.map((order, index) => ({
        order_id: order.orderId,
        courier_id: routeData.params.id,
        ord: index + 1,
      }))
    );
  });

  const changeNameCourier = () => {
    updateOrdCouriers(null, courier.value.name);
  };

  const changeCourier = (courierId, orderId) => {
    unbindCourierToOrder(orderId, routeData.params.id);
    bindCourierToOrder([{ order_id: orderId, courier_id: courierId }]);
    fetchCourier(date.value);
  };

  const reloadCourierOrders = (name) => {
    if (name === 'tomorrow') {
      fetchCourier(moment().add(1, 'days').format('YYYY-MM-DD'));
      return;
    }
    if (name === 'today') {
      fetchCourier(today);
      return;
    }
    fetchCourier(name);
  };

  const deliveredOrder = (orderId, isDelivered) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/updateCourier`, {
      id: routeData.params.id,
      payload: { order_id: orderId, isDelivered: !isDelivered },
    });
    reloadCourierOrders(editableTabsValue.value);
  };
  const removeOrderInCourier = (orderId) => {
    unbindCourierToOrder(orderId, routeData.params.id);
    reloadCourierOrders(editableTabsValue.value);
  };

  watch(editableTabsValue, (name) => {
    reloadCourierOrders(name);
  });

  const addTab = () => {
    editableTabs.value.push({
      title: moment(date.value).locale('ru').format('ll'),
      name: date.value,
    });
    editableTabsValue.value = date.value;
    localStorage.setItem('courierTabs', JSON.stringify(editableTabs.value));
    isVisibleAddTab.value = false;
  };

  const removeTab = (tab) => {
    if (tab === 'today' || tab === 'tomorrow') {
      return;
    }
    editableTabs.value = editableTabs.value.filter((t) => t.name !== tab);
    editableTabsValue.value = 'today';
    localStorage.setItem('courierTabs', JSON.stringify(editableTabs.value));
  };

  const createCourierOrder = async () => {
    const createResult = await createCourierOrderRef.value.open();
    if (createResult) {
      Notification.success({
        title: 'Успех',
        message: 'Заказы успешно добавлены',
      });
      setTimeout(() => {
        reloadCourierOrders(editableTabsValue.value);
      }, 1000);
    }
  };

  const generateFile = async () => {
    const {
      data: { url },
    } = await store.dispatch(`${COURIER_STORE_MODULE_NAME}/generateCourierFile`, {
      id: routeData.params.id,
      params: {
        preview: false,
        date: dateCourierList.value,
      },
    });
    window.location.href = url;
  };

  const previewFile = async () => {
    const {
      data: { url },
    } = await store.dispatch(`${COURIER_STORE_MODULE_NAME}/generateCourierFile`, {
      id: routeData.params.id,
      params: { preview: true, date: dateCourierList.value },
    });
    previewFileTable(url);
  };

  const deleteFile = async ({ id }) => {
    await store.dispatch(`${COURIER_STORE_MODULE_NAME}/deleteDocument`, { id });
    fetchCourier(date.value);
  };

  const formatTimeDelivery = (time) => {
    if (!time) {
      return '-';
    }
    if (time === 'NULL') {
      return '-';
    }
    return JSON.parse(time).join('-');
  };

  const tableRowClassName = ({ row }) => {
    if (row.isDelivered) {
      return 'success-row';
    }
    return '';
  };

  return {
    courier,
    formatAddress,
    formatComment,
    courierOrders,
    couriers,
    changeCourier,
    removeOrderInCourier,
    editableTabs,
    editableTabsValue,
    removeTab,
    addTab,
    date,
    isVisibleAddTab,
    documentsPreviewRef,
    previewFileTable,
    createCourierOrder,
    createCourierOrderRef,
    generateFile,
    previewFile,
    dateCourierList,
    fetchCourier,
    changeNameCourier,
    deleteFile,
    formatTimeDelivery,
    tableRowClassName,
    deliveredOrder,
  };
};
